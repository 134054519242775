import React from 'react';
import ThankYouDownload from '../../../assets/thank-you-download.png';
import { Button } from '@bcmi-labs/react-components';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import SEO from '../../../components/seo';
import Layout from '../../../components/layout';
import '../donate/style.scss';
import './style.scss';

const ThankYou = () => {
  return (
    <Layout currentLocale="en">
      <Helmet
        bodyAttributes={{
          class: 'donate-page-body',
        }}
      />
      <SEO title="Download thank you" />
      <main className="donate-page page">
        <section>
          <div className="page-content download-thank-you">
            <img src={ThankYouDownload} alt="Thank you" />
            <h2>Thank you for downloading!</h2>
            <p>
              You're always welcome to sign up in the future. Keep up with exclusive updates, tutorials, events and join
              our community to empower creators worldwide anytime.
            </p>
            <Link to={process.env.GATSBY_HOMEPAGE_URL} target="_blank">
              <Button variant="primary">GO TO HOME PAGE</Button>
            </Link>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default ThankYou;
